import React from "react";
import { Request } from "../classes/Request";
import { NotificationManager } from "react-notifications";
import { Button, Modal } from "react-bootstrap";
import Cookies from 'js-cookie';
import { NewClassRequest } from "../classes/NewClassRequest";

export const initForm = {
  username: "",
  password: "",
  confirm_password: "",
  email: "",
  regime: "",
  user_type: ""
};

/**********************************************************FORGOT PASSWORD************************************************************************/

export const register = async (form, recaptcha, props, subdomain, setErrors) => {
  if (recaptcha && recaptcha.trim().length > 0) {
    const request = new NewClassRequest(
      `${process.env.REACT_APP_URL_API_LARAVEL}/auth`,
      "post",
      null,
      {
        app_id: 2,
        active: 0,
        username: form.username,
        password: form.password,
        password_confirmation: form.password_confirmation,
        regime: form.regime,
        user_type: form.user_type,
        email: form.email,
        domain_name: Cookies.get('domain_name_02')
      }
    );
    const validateRegister = await request.executeRequest();

    if ( validateRegister.code === 200 ) {
      //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
      if (
        !validateRegister.response.error &&
        typeof validateRegister.response.message === "string" &&
        validateRegister.response.message !== ""
      ) {

        setTimeout(() => {
          NotificationManager.success(validateRegister.response.message);
        }, 500);

        props.history.push(`/${subdomain}/`);
      }
      else if ( 
        validateRegister.response.error && 
        validateRegister.response.message && 
        typeof validateRegister.response.message &&  
        validateRegister.response.message !== "" ) {

          const errors = validateRegister.response.message;
          setErrors(errors);
          NotificationManager.error("Existen errores que impiden continuar, revisa los campos marcados en rojo", "Errores: ");
      }
    }
    else {
      if ( validateRegister.response && validateRegister.response.message ) {
        const errors = validateRegister.response.message;
        setErrors(errors);
        NotificationManager.error("Existen errores que impiden continuar, revisa los campos marcados en rojo", "Errores: ");
      }
    }
  } else {
    const errors = {
      recaptcha:["Este campo es obligatorio"]
    }
    setErrors(errors);
  }
};

/**********************************************************FORGOT PASSWORD************************************************************************/
/**
 * Function to get terms and conditions of use.
 * @returns {Promise<{code: *, message: *}|any>}
 */
export const termsRequest = async (
  setTermsTitle,
  setTermsText,
  setCanShowModal
) => {
  const { domain_id_02 } = Cookies.get();
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/terms/${domain_id_02}/conditions?token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );
  const token = btoa(process.env.REACT_APP_TOKEN_WITHOUT_AUTHENTICATION);
  const termsRequestData = await request.executeRequest(false, token);
  if (termsRequestData.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    setTermsTitle(termsRequestData.data.data.data.content.title);
    setTermsText(termsRequestData.data.data.data.content.description);
  } else {
    setCanShowModal(false);
  }
};
/***************************************************************************************************************************************************/

export const ModalTermsAndConditions = (
  show,
  setHandleTermsModal,
  termsText,
  termsTitle
) => {
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setHandleTermsModal(false);
        }}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>{termsTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            dangerouslySetInnerHTML={{ __html: termsText }}
            style={{ maxHeight: "60vh", overflowY: "scroll" }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setHandleTermsModal(false);
            }}
          >
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
