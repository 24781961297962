import React, { useState, useEffect } from "react";
import { Modal, Button, Dropdown } from "react-bootstrap";
import DigitalSignature from "../digital_signature/DigitalSignature";

function ModalSignature(props) {
  const [show, setShow] = useState(false);
  const [showDowloadDocument, setShowDowloadDocument] = useState(false);
  const [finalDocumentUrlPDF, setFinalDocumentUrlPDF] = useState(
    props.el.extra_attributes.document_information.signature
      ? props.el.extra_attributes.document_information.signature
      : ""
  );
  const [signInStep, setSignInStep] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (signInStep) {
      props.setNextButtonDisable(false);
    }
  }, [signInStep]);

  useEffect(() => {
    if (props.el.extra_attributes.signature) {
      if (props.el.extra_attributes.current_stage_signed) {
        props.setNextButtonDisable(false);
      } else {
        props.setNextButtonDisable(true);
      }
    } else {
      props.setNextButtonDisable(false);
    }
  }, [showDowloadDocument]);

  return (
    <>
      <Button variant="primary" onClick={handleShow} disabled={props.mode === 2 ? true : false}>
        Firmar Documento
      </Button>
      {
        showDowloadDocument || props.el.extra_attributes.document_information.signature.length > 0 &&
          <Dropdown style={{ display: "inline", marginLeft: "1em" }}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {finalDocumentUrlPDF.length > 1 ? 'Descargar documentos' : 'Descargar documento'}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {finalDocumentUrlPDF.map((file, i) => (
                <Dropdown.Item key={i} href={file['url']}>{'Documento # ' + (i + 1)}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
      }
      <Modal show={show} onHide={handleClose} animation={true} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DigitalSignature
            el={props.el}
            onChangeHandler={props.onChangeHandler}
            hasErrors={props.el.error}
            errorDigital={props.errorDigital}
            state={props.state}
            setState={props.setState}
            mode={props.mode}
            stage={props.stage}
            isDocument={true}
            htmlIntoBase64={props.htmlIntoBase64}
            showModalSignature={setShow}
            showDowloadDocument={setShowDowloadDocument}
            finalDocumentUrlPDF={finalDocumentUrlPDF}
            setFinalDocumentUrlPDF={setFinalDocumentUrlPDF}
            setNextButtonDisable={props.setNextButtonDisable}
            setSignInStep={setSignInStep}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalSignature;
