import React, { useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import DigitalSignatureModal from "../digital_signature/digitalSignatureModal";
import { Col } from "react-flexbox-grid";

const TinySignature = props => {
  const [text, setText] = useState(props.state[props.el.name]);

  useEffect(() => {
    if (props.state[props.el.name] && props.state[props.el.name]['value']) {
      let field = props.state[props.el.name];
      if (typeof field['value'] === 'object') {
        //EXECUTING PROCEDURE
        setText(field['value']['value']);
      } else {
        //CONTINUE PROCEDURE
        setText(field['value']);
      }
    } else {
      const field = props.el;
      const value = field['value'] !== '' ? field['value'] : 
      field['extra_attributes']['document_information']['data'];
      setText(value);
      props.onChangeHandler(value, props.el.name, props.el.type);
    }
  }, []);

  const handleEditorChange = e => {
    setText(e.target.getContent());
    props.onChangeHandler(e.target.getContent(), props.el.name, props.el.type);
  };

  return (
    <Col xs={12} className="p-10" style={{minHeight:"1250px"}}>
      <Col className="p-5" xs={12}>
        <DigitalSignatureModal {...props} htmlIntoBase64={text} />
      </Col>
      <Editor
        apiKey="bugg7r7e8dy612zqv84qvfm9qtb8lm7r1utvvtlsdvtnd9ih"
        initialValue={text}
        value={text}
        init={{
          plugins: "link image code",
          toolbar:
            "undo redo | bold italic | alignleft aligncenter alignright | code",
          language: "es_MX",
          language_url: "/langs/es_MX.js"
        }}
        onChange={handleEditorChange}
      />
    </Col>
  );
};

export default TinySignature;
