import React, { useState, useContext } from "react";
import queryString from "query-string";
import NotFound from "../error_page/404";
import { Card, Form, Container, Row, Col } from "react-bootstrap";
import GroupButtonsForm from "../helpers_components/GroupSubmitFormButtons";
import { initFormRecovery, recoveryPassword } from "./forgot_password_logic";
import LoaderContext from "../layout/shared/loader_context";
import OneColumn from "../layout/containers/OneColumn";
import DomainContext from "../domains/domainPathContext";
import Cookies from "js-cookie";


const RecoveryPassword = props => {
  const subdomain = useContext(DomainContext);
  const [form, setForm] = useState(initFormRecovery);
  const Loader = useContext(LoaderContext);
  const layout = JSON.parse(Cookies.get("layout_02"));

  let pageConfig = {
    active: "",
    title: ""
  };

  const handleChangeForm = event => {
    setForm({
      ...form,
      [event.target.name]: event.target.value
    });
  };

  const onSubmitFormHandler = async event => {
    event.preventDefault();
    Loader.show(true);
    await recoveryPassword(
      form,
      getParams.reset_password_token,
      props,
      subdomain
    );
    Loader.show(false);
  };

  const getParams = queryString.parse(props.location.search);
  if (typeof getParams.reset_password_token !== "undefined") {
    return (
      <OneColumn
        {...props}
        pageConfig={pageConfig}
        hideTopMenu={true}
        fluid={true}
        noTitle={true}
      >
        <Container
          fluid={true}
          className="bg-size-cover"
          style={{
            minHeight: "76vh",
            backgroundImage: layout && layout['app_background_undefined_session'] ? 
              `url(${layout['app_background_undefined_session']})` :
              "url(" + process.env.PUBLIC_URL + "/img/login_wallpaper.png)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center"
          }}
        >
          <Container>
            <Row>
              <Col
                sm={12}
                md={{ span: 10, offset: 1 }}
                lg={{ span: 6, offset: 3 }}
                className="mY-40"
              >
                <Card>
                  <Card.Header>
                    <Container>
                      <Row>
                        <Col
                          xs={12}
                          md={{ span: 10, offset: 1 }}
                          className="pT-30 pB-20"
                        >
                          <h3 className="mb-0">Recuperación de contraseña</h3>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Header>
                  <Card.Body>
                    <Container>
                      <Row>
                        <Col
                          xs={12}
                          md={{ span: 10, offset: 1 }}
                          className="pT-30"
                        >
                          <p className="mB-30">
                            Para recuperar la contraseña por favor llena el
                            siguiente formulario
                          </p>

                          <Form
                            onSubmit={e => onSubmitFormHandler(e)}
                            className="mB-20"
                          >
                            <Form.Group controlId="formBasicPassword">
                              <Form.Label className="font-weight-bold">
                                * Nueva contraseña
                              </Form.Label>
                              <Form.Control
                                type="password"
                                placeholder="Nueva contraseña"
                                autoFocus
                                onChange={handleChangeForm}
                                name="password"
                              />
                            </Form.Group>

                            <Form.Group controlId="formBasicConfirmationPassword">
                              <Form.Label className="font-weight-bold">
                                * Confirma la contraseña
                              </Form.Label>
                              <Form.Control
                                type="password"
                                placeholder="Confirmar nueva contraseña"
                                onChange={handleChangeForm}
                                name="password_confirmation"
                              />
                            </Form.Group>

                            <GroupButtonsForm
                              primaryText="Continuar"
                              secondaryText="Cancelar"
                              urlToCancel={`/${subdomain}/`}
                            />
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                  <Card.Footer>
                    <p className="mB-20" />
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Container>
        </Container>
      </OneColumn>
    );
  } else {
    return <NotFound />;
  }
};

export default RecoveryPassword;
