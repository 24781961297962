import React, { useState, useEffect, useContext } from "react";
import { Col, Row } from "react-flexbox-grid";
import DynamicForm from "../../dynamic_form/DynamicForm";
import OneColumn from "../../layout/containers/OneColumn";
import { executeStage, previousHandler } from "./render_form_logic";
import { startProcess } from "../procedure_logic";
import { resumeProcess } from "../process_list_logic";
import LoaderContext from "../../layout/shared/loader_context";

const FirstStep = props => {
  const LoaderCtxt = useContext(LoaderContext);
  const [sequence, setSequence] = useState(
    props.history.location.state && 
    props.history.location.state.params ?
    props.history.location.state.params.sequence :
    null );
  const [form, setForm] = useState(
    props.history.location.state && 
    props.history.location.state.params && 
    props.history.location.state.params.form ?
    props.history.location.state.params.form :
    null
  );
  const [lastStep, setLastStep] = useState(false);
  const [stage, setStage] = useState(
    props.history.location.state && 
    props.history.location.state.params && 
    props.history.location.state.params.stage ? 
    props.history.location.state.params.stage : 
    null
  );
  const [flagError, setFlagError] = useState(false);
  const [mode, setMode] = useState(
    props.history.location.state && 
    props.history.location.state.params && 
    props.history.location.state.params.mode ? 
    props.history.location.state.params.mode :
    null
  );
  const [applicant, setApplicant] = useState(
    props.history.location.state && 
    props.history.location.state.params && 
    props.history.location.state.params.applicant ?
    props.history.location.state.params.applicant :
    null
  );
  const [formTitle, setFormTitle] = useState(
    props.history.location.state && 
    props.history.location.state.params && 
    props.history.location.state.params.formTitle ?
    props.history.location.state.params.formTitle :
    null
  );
  const [previosUrl, setPreviousUrl] = useState(
    props.history.location.state && 
    props.history.location.state.params && 
    props.history.location.state.params.previous_url ? 
    props.history.location.state.params.previous_url : 
    ""
  );
  const [mounted, setMounted] = useState("");

  useEffect(() => {
    (async function() {
      const actionTo = localStorage.getItem("actionTo") ? JSON.parse(localStorage.getItem("actionTo")) : null;

      //SE TRAE INFORMACIÓN ALMACENADA EN LOCATION.STATE LA CUAL SE SETEA EN 
      //PROCEDURE_LOGIC.JS EN LA FUNCION STARTPROCESS SI TRAEMOS ESTA VARIABLE NO SE NECESITA HACER
      // NADA MAS QUE RENDEREAR LA VISTA DE ESTE COMPONENTE
      if (
        props.history.location.state &&
        actionTo && 
        actionTo.action !== 'continue'
      ) {
        setMounted("existState");
      }
      // SINO SE TRAE ESTA VARIABLE HAREMOS LA PETICION QUE SE HACE EN PROCEDURE_LOGIC.JS EN LA FUNCION STARTPROCESS
      // Y SETEAMOS LA INFORMACION NECESARIA PARA RENDEREAR EL COMPONENTE
      // Y LO RENDEREAMOS HASTA QUE TODA LA INFO ESTE SETEADA Y LA GUARDAMOS EN EL LOCATION.STATE POR SI MAS 
      // ADELANTE SE NECESITA, LO CUAL SI OCURRE EN EL DYNAMICFORM 
      else {
        const startOrContinueProcessData = actionTo.action === "start" ? await startProcess( actionTo.processId, null, props.match.params.subdomain ) : await resumeProcess(actionTo.procedure_id, null, props.match.params.subdomain );

        setSequence(startOrContinueProcessData.meta.current_step);
        setForm(startOrContinueProcessData.form);
        setStage(startOrContinueProcessData.meta.stage_id);
        setMode(startOrContinueProcessData.meta.mode);
        setApplicant(startOrContinueProcessData.meta.applicant_id);
        setFormTitle(startOrContinueProcessData.meta.form_name);

        props.history.push( {
          state: {
            params: {
              stage: startOrContinueProcessData.meta.stage_id,
              sequence: startOrContinueProcessData.meta.current_step,
              form: startOrContinueProcessData.form,
              formTitle: startOrContinueProcessData.meta.form_name,
              mode: startOrContinueProcessData.meta.mode,
              applicant: startOrContinueProcessData.meta.applicant_id
            }
          }
        })
        setMounted("addState");
      };
    })();
  }, []);

  const previousUrlHandler = async url => {
    await previousHandler(
      url,
      setFormTitle,
      setPreviousUrl,
      setForm,
      setSequence,
      setMode
    );
  };

  const onSubmitHandler = async (form, partial = false) => {
    LoaderCtxt.show(true);
    await executeStage(
      form,
      partial,
      sequence,
      props,
      setFormTitle,
      setSequence,
      setStage,
      setPreviousUrl,
      setFlagError,
      setForm,
      setLastStep,
      setMode,
      setApplicant
    );
    LoaderCtxt.show(false);
  };

  let pageConfig = {
    active: "home",
    title: "Form"
  };

  if ( mounted === "existState" || mounted === "addState" ) return (
    <OneColumn
      {...props}
      pageConfig={pageConfig}
      hideTopMenu={true}
      noTitle={true}
    >
      <section className="home">
        <Row>
          <Col xs={12}>
            <DynamicForm
              onSubmit={(models, partial) => {
                onSubmitHandler(models, partial);
              }}
              history={props.history}
              form={form}
              lastStep={lastStep}
              formTitle={formTitle}
              stage={stage}
              hasErrors={flagError}
              setHasErrors={setFlagError}
              previousUrl={previosUrl}
              previousUrlHandler={previousUrlHandler}
              setLastStep={setLastStep}
              mode={mode}
              applicant={applicant}
            />
          </Col>
        </Row>
      </section>
    </OneColumn>
  )
  else return (
    <div></div>
  );
};

export default FirstStep;
