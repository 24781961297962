import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { getUserConfirmData } from "./confirm_registration_logic";

const InformationTable = props => {
  const [user, setUser] = useState({});
  const token = props.token;

  useEffect(() => {
    (async function() {
      await getUserConfirmData(token, setUser);
    })();
  }, []);

  return (
    <div style={!props.show ? { display: "none" } : { display: "block" }}>
      <Table responsive bordered size="sm" style={{ marginTop: "15px" }}>
        <thead>
          <tr>
            <th>Nombre de usuario:</th>
            <th>Correo electrónico:</th>
            <th>Tipo Solicitante:</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{user.username}</td>
            <td>{user.email}</td>
            <td>{user.user_type}</td>
          </tr>
        </tbody>
      </Table>

      <div style={{ marginTop: "20px" }}>
        <span>
          <b>IMPORTANTE:</b> para continuar con el registro, por favor
          proporcione la información que a continuacion se solicita.
        </span>
      </div>
    </div>
  );
};

export default InformationTable;
