import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Grid } from "react-flexbox-grid";
import DomainContext from "../../domains/domainPathContext";

const TopMenu = props => {
  const subdomain = useContext(DomainContext);

  return (
    <Grid fluid={true} className="principal-nav">
      <Grid>
        <ul className="nav nav-tabs">
          <li className="nav-item mr-1">
            <Link
              to={`/${subdomain}/home`}
              className={
                "nav-link " +
                (props.pageConfig.active === "home" ? "active" : "")
              }
            >
              <i className="ti-home mr-1" />
              Home
            </Link>
          </li>
          <li className="nav-item mr-1">
            <Link
              to={`/${subdomain}/formalities`}
              className={
                "nav-link " +
                (props.pageConfig.active === "my_formalities" ? "active" : "")
              }
            >
              <i className="ti-id-badge mr-1" />
              Mis trámites
            </Link>
          </li>
          <li className="nav-item mr-1">
            <Link
              to={`/${subdomain}/procedure-list`}
              className={
                "nav-link " +
                (props.pageConfig.active === "all_formalities" ? "active" : "")
              }
            >
              <i className="ti-agenda mr-1" />
              Trámites disponibles
            </Link>
          </li>
        </ul>
      </Grid>
    </Grid>
  );
};

export default TopMenu;
