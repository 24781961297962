import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Badge, Image, Nav, Navbar, NavDropdown } from "react-bootstrap";
import Cookies from "js-cookie";
import { logOut, setImgAvatar } from "./navbar_logic";
import { Link } from "react-router-dom";
import NotificationListContext from "../../alert_notification_table/notification_context";
import LoaderContext from "./loader_context";
import DomainContext from "../../domains/domainPathContext";

const NavBar = props => {
  //GET COOKIES
  const subdomain = useContext(DomainContext);
  const { isAuth_02, names_02, paternal_surname_02, uid_02 } = Cookies.get();

  //INITIAL STATE
  const countNotifications = useContext(NotificationListContext);
  const Loader = useContext(LoaderContext);
  const [avatar, setAvatar] = useState({});
  const [isResponsive, setIsResponsive] = useState(window.innerWidth < 992);
  const layout = JSON.parse(Cookies.get("layout_02"));
  const { authentication_token_02 } = Cookies.get();

  useEffect(() => {
    (async function() {
      const { avatar_02 } = Cookies.get();
      if (avatar_02 && avatar_02 !== "null") {
        setAvatar({
          url: avatar_02,
          type: "request",
          show: false
        });
      } else {
        setAvatar({
          url: "/img/user_avatar.png",
          type: "local",
          show: true
        });
      }
    })();
  }, []);

  //LOGOUT METHOD
  const onLogOutHandler = async () => {
    Loader.show(true);
    await logOut(props, subdomain);
    Loader.show(false);
  };

  useLayoutEffect(() => {
    function updateSize() {
      setIsResponsive(window.innerWidth < 992);
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const renderNames = (data) => {
    if (data === 'null'){
      return '';
    }
    return data;
  };

  const profileWrapper = () => {
    if (isAuth_02) {
      return (
        <div className="nilo-profile-wrapper">
          <div className="image-wrapper">
            <img 
              src={ avatar && avatar['type'] === 'local' ? process.env.PUBLIC_URL + avatar['url'] : '' }
              ref={ (element) => {
                if (element && avatar['show'] && avatar['type'] === 'request') {
                  element.alt = "Cargando...";

                  fetch(avatar['url'], { headers:{ Authorization: authentication_token_02 } })
                  .then( response => response.blob().then(
                    blob => {
                      let temporaryURL = URL.createObjectURL(blob);
                      element.src = temporaryURL;
                    }
                  ))
                }
              }}
              alt="Avatar" 
            />
          </div>
          <div className="profile-info-wrapper">
            <label className="name w-100">
              {renderNames(names_02)} {renderNames(paternal_surname_02)}
            </label>
            <label className="username w-100">{uid_02}</label>
            <Link to={`/${subdomain}/edit_profile`} className="btn btn-primary">
              Editar Perfil
            </Link>
          </div>
        </div>
      );
    }

    return "";
  };

  return (
    <>
      <Navbar collapseOnSelect expand="lg">
        <Navbar.Brand as={Link} to={`/${subdomain}/home`}>
          {layout.app_header_logo_url_image !== null &&
            <img src={layout.app_header_logo_url_image} alt="application logo" />
          }
        </Navbar.Brand>
        <Navbar.Text>{props.title ? props.title : ""}</Navbar.Text>
        <Navbar.Toggle 
          onClick={ avatar.type === "local" ? null : () => setAvatar({...avatar, show:!avatar.show})} 
          aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <div className={isResponsive ? "d-block" : "d-none"}>
              {isResponsive ? profileWrapper() : ""}
              <Nav.Link as={Link} to={`/${subdomain}/alert-notifications`}>
                <i className="far fa-image" /> Notificaciones {""}
                <Badge pill variant="danger">
                  {countNotifications.count === 0
                    ? ""
                    : countNotifications.count}
                </Badge>{" "}
                <span className="sr-only">unread messages</span>
              </Nav.Link>
              <Nav.Link as={Link} to={`/${subdomain}/change-password`}>
                <i className="fas fa-key" /> Cambiar Contraseña
              </Nav.Link>
              <Nav.Link onClick={() => onLogOutHandler()}>
                <i className="fas fa-sign-out-alt" /> Cerrar Sesión
              </Nav.Link>
            </div>
          </Nav>
          <Nav>
            <div
              className={!isResponsive ? "d-block" : "d-none"}
              style={{ minWidth: "14rem" }}
              onClick={ avatar.type === "local" ? null : () => setAvatar({...avatar, show:!avatar.show})} 
            >
              <NavDropdown
                title={setImgAvatar(countNotifications.count)}
                id="collasible-nav-dropdown"
                style={isAuth_02 ? { display: "block" } : { display: "none" }}
              >
                {!isResponsive ? profileWrapper() : ""}
                <NavDropdown.Item as={Link} to={`/${subdomain}/avatar_config`}>
                  <i className="fas fa-camera-retro" /> Configurar avatar
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to={`/${subdomain}/alert-notifications`}
                >
                  <i className="far fa-image" /> Notificaciones {""}
                  <Badge pill variant="danger">
                    {countNotifications.count === 0
                      ? ""
                      : countNotifications.count}
                  </Badge>{" "}
                  <span className="sr-only">unread messages</span>
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to={`/${subdomain}/change-password`}
                >
                  <i className="fas fa-key" /> Cambiar Contraseña
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => onLogOutHandler()}>
                  <i className="fas fa-sign-out-alt" /> Cerrar Sesión
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default NavBar;
