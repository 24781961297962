import React from "react";
import { Dropdown, Col, Modal, Row, Card, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { Request } from "../classes/Request";
import Cookies from "js-cookie";
import { xmlHttpRequestManualDownload as requestDownloadPDF } from "../../components/procedure_list/procedure_list_logic";

/**********************************************************FORGOT PASSWORD************************************************************************/
export const getListOfTask = async (props, setTaskDetails, subdomain) => {
  const { userId_02 } = Cookies.get();
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/executed_stages/${props.el.procedure_id}/${userId_02}?token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );

  const getListOfTaskData = await request.executeRequest();
  if (getListOfTaskData.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE

    const taskList = getListOfTaskData.data.data.data.stages.map(
      (el, index) => {
        return (
          <Dropdown.Item
            key={index}
            onClick={() => getStageDetail(el.id, props.setShowModal, props.setModalElements)}
          >
            {el.name}
          </Dropdown.Item>
        );
      }
    );
    setTaskDetails(taskList);
  } else {
    //MOSTRAR ALERTA ERRONEA CON EL MENSAJE QUE RETORNA LA API COMPONENTE

    setTaskDetails(
      <Dropdown.Item href="#/action-1">
        No se encontraron pasos para esta etapa
      </Dropdown.Item>
    );
  }
};

const formatDate = DateString => {
  let date = new Date(DateString);
  let month = date.getMonth() + 1;
  return (
    date.getDate() +
    "-" +
    (month < 9 ? "0" : "") +
    month +
    "-" +
    date.getFullYear()
  );
};

export const getStageDetail = async (idStage, setShowModal, setModalElements) => {
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/stage/detail/${idStage}?token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );
  const getStageDetailData = await request.executeRequest();
  if (getStageDetailData.code === 200) {
    setModalElements(
      <>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Seguimiento Etapa
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{'maxHeight': 'calc(100vh - 210px)', 'overflowY': 'auto'}}>
          <Row className="pB-40">{makeFormsBasedResponse(getStageDetailData.data.data.data.stage.forms)}</Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>setShowModal(false)}>Cerrar</Button>
        </Modal.Footer>
     </>
    );
    setShowModal(true);
  }
};

const makeFormBasedFields = fields => {
  const formFields = fields.map((el, index) => {
    if (typeof el.value == "object" && el.value.length >= 0) {

      if ( el.type === "grid" ) {
        var dataIndex = el.value;
        var columns = [];
        var data = [];
        
        dataIndex.forEach((elements, index) => {            

          var arrayRender = [];
          
          elements.forEach((element, i)=> {
            if ( element.type !== "action" && index === 0 ) {
              columns.push({ dataField: element.description, text: element.description })
            }
            if( element.type === 'text' || element.type === 'check' ) {
              arrayRender[element.description] = element.value; 
            } else if ( element.type === 'select' ) {
              arrayRender[element.description] = element.value.label;
            } else if ( element.type === 'date' ) {
              arrayRender[element.description] = formatDate(element.value);
            }
          })

          data.push(arrayRender)
        });

        return (
          <tr>
            <td colSpan={2}>
              <h4 className="mY-20 text-center">{el.label}</h4>
              <BootstrapTable
                remote
                keyField="id"
                data={data}
                columns={columns}
                className="overflow-hidden w-100"
              />
             </td>
          </tr>
        )

      }

      return (
        <tr key={index}>
          <td
            className="font-weight-bold text-right"
            style={{ width: "40%" }}
          >
            {el.label}:
          </td>
          <td className="break-word text-justify">
            {el.value.map((option, indexOption) => {
              let options = "";

              // add options selected
              for (let key in option) {
                options = option[key] ? key + ", " : "";
              }

              return options;
            })}
          </td>
        </tr>
      );
    } else if (String(el.value).includes("http")) {
      return (
        <tr key={index}>
          <td
            className="font-weight-bold text-right"
            style={{ width: "40%" }}
          >
            {el.label}:
          </td>
          <td className="break-word text-justify">
            <button onClick={() => downloadPDF(el)} className="btn btn-primary">
              <i className="ti-download mr-2" title="Descargar" />
              Descargar
            </button>
          </td>
        </tr>
      );
    } else if (
      typeof el.value === 'object' && 
      el.value.url && 
      typeof el.value.url === 'string' &&
      el.value.url.includes('http') && 
      el.type === 'digital-document'
    ) {
      return (
        <tr key={index}>
          <td
            className="font-weight-bold text-right"
            style={{ width: "40%" }}
          >
            {el.label}:
          </td>
          <td className="break-word text-justify">
            <button onClick={() => downloadPDF({label: el.label, value:el.value.url})} className="btn btn-primary">
              <i className="ti-download mr-2" title="Descargar" />
              Descargar
            </button>
          </td>
        </tr>
      );
    }

    return (
      <tr key={index}>
        <td className="font-weight-bold text-right" style={{ width: "40%" }}>
          {el.label}:
        </td>
        <td className="break-word text-justify">{el.value}</td>
      </tr>
    );
  });
  return formFields;
};

const makeFormsBasedResponse = form => {
  if (form.length > 0) {
    const responseFormFormatted = form.map((el, index) => {
      return (
        <Col>
          <Card className="mB-20">
            <Card.Body>
              <div className="table-responsive">
                <table className="nilo w-100">
                  <thead>
                    <tr>
                      <th colSpan={2} className="text-center p-20">
                        <h4 className="mb-0">{el.name}</h4>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{makeFormBasedFields(el.fields)}</tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      );
    });
    return responseFormFormatted;
  } else {
    return (
      <div className="alert alert-warning" role="alert">
        La etapa no se encuentra vinculada a ningún trámite, favor de intentarlo
        de nuevo más tarde.
      </div>
    );
  }
};

const downloadPDF = (el) => {
  requestDownloadPDF(el['value'], function (blob) {
    let a = document.createElement('a');
    let blobb = new Blob([blob], {type: 'application/pdf'});
    let url = URL.createObjectURL(blobb);
    const name = el['label'] + ".pdf";
    a.href = url;
    a.download = name;
    a.click();  
  });
};