import Cookies from "js-cookie";
import React from "react";
import { Col, Row } from "react-flexbox-grid";
import { Badge } from "react-bootstrap";
import DeleteProcedure from "./DeleteProcedure";
import { Request } from "../classes/Request";
import ViewDetailSelect from "./ViewDetail";
import { NotificationManager } from "react-notifications";

export const statusProcess = [
  { id: 1, name: "Pendiente" },
  { id: 2, name: "En revisión" },
  { id: 3, name: "Cancelado" },
  { id: 4, name: "Pausado" },
  { id: 5, name: "Finalizado" }
];

export const columns = [
  {
    dataField: "procedure_id",
    text: "Id",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { minWidth: "4rem", width: "8.3%", textAlign: "center" }
  },
  {
    dataField: "name",
    text: "Nombre del Trámite",
    sort: true,
    style: { textAlign: "left" },
    headerStyle: { width: "25%", textAlign: "left" }
  },
  {
    dataField: "folio",
    text: "Folio",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { minWidth: "8rem", width: "8.3%", textAlign: "center" }
  },
  {
    dataField: "status",
    text: "Estado",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { minWidth: "10rem", width: "10.3%", textAlign: "center" }
  },
  {
    dataField: "current_stage",
    text: "Etapa(s)",
    sort: true,
    headerStyle: { width: "16.6%", textAlign: "center" }
  },
  {
    dataField: "color",
    text: "Vencimiento",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { minWidth: "16rem", width: "16.3%", textAlign: "center" }
  },
  {
    dataField: "action",
    text: "Acciones",
    sort: true,
    headerStyle: { width: "16.6%", textAlign: "center" }
  }
];

export const columnsCanceled = [
  {
    dataField: "procedure_id",
    text: "Id",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { minWidth: "4rem", width: "8.3%", textAlign: "center" }
  },
  {
    dataField: "name",
    text: "Nombre del Trámite",
    sort: true,
    style: { textAlign: "left" },
    headerStyle: { width: "25%", textAlign: "left" }
  },
  {
    dataField: "folio",
    text: "Folio",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { minWidth: "8rem", width: "8.3%", textAlign: "center" }
  },
  {
    dataField: "status",
    text: "Estado",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { minWidth: "10rem", width: "10.3%", textAlign: "center" }
  },
  {
    dataField: "current_stage",
    text: "Etapa(s)",
    sort: true,
    headerStyle: { width: "16.6%", textAlign: "center" }
  },
  {
    dataField: "action",
    text: "Acciones",
    sort: true,
    headerStyle: { width: "16.6%", textAlign: "center" }
  }
];

export const columnsFinished = [
  {
    dataField: "procedure_id",
    text: "Id",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { minWidth: "4rem", width: "8.3%", textAlign: "center" }
  },
  {
    dataField: "name",
    text: "Nombre del Trámite",
    sort: true,
    style: { textAlign: "left" },
    headerStyle: { width: "25%", textAlign: "left" }
  },
  {
    dataField: "folio",
    text: "Folio",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { minWidth: "8rem", width: "8.3%", textAlign: "center" }
  },
  {
    dataField: "status",
    text: "Estado",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { minWidth: "10rem", width: "10.3%", textAlign: "center" }
  },
  {
    dataField: "action",
    text: "Acciones",
    sort: true,
    headerStyle: { width: "16.6%", textAlign: "center" }
  }
];

export const columnsReview = [
  {
    dataField: "procedure_id",
    text: "Id",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { minWidth: "4rem", width: "8.3%", textAlign: "center" }
  },
  {
    dataField: "name",
    text: "Nombre del Trámite",
    sort: true,
    style: { textAlign: "left" },
    headerStyle: { width: "25%", textAlign: "left" }
  },
  {
    dataField: "folio",
    text: "Folio",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { minWidth: "8rem", width: "8.3%", textAlign: "center" }
  },
  {
    dataField: "status",
    text: "Estado",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { minWidth: "10rem", width: "10.3%", textAlign: "center" }
  },
  {
    dataField: "current_stage",
    text: "Etapa(s)",
    sort: true,
    headerStyle: { width: "16.6%", textAlign: "center" }
  },
  {
    dataField: "percentage_completation",
    text: "Avance",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { minWidth: "8rem", width: "8.3%", textAlign: "center" }
  },
  {
    dataField: "action",
    text: "Acciones",
    sort: true,
    headerStyle: { width: "16.6%", textAlign: "center" }
  }
];

/*export const getMyProcessData = async (
  props,
  setProcessData,
  setTotalProcedures,
  sizePerPage,
  page
) => {
  const { userId_03 } = Cookies.get();

  const request = new Request(
    `${
      process.env.REACT_APP_URL_API_LARAVEL
    }/procedure/capture/${userId_03}/${sizePerPage}?page=${page}&token=${
      process.env.REACT_APP_TOKEN
    }`,
    "get",
    null,
    {}
  );
  const getMyProcessDataRequest = await request.executeRequest();
  if (getMyProcessDataRequest.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE TALOBILLO
    setTotalProcedures(getMyProcessDataRequest.data.data.data.total);
    const dataProcessFormatted = await FormattedActualStageMyProcesses(
      getMyProcessDataRequest.data.data.data.procedures,
      props
    );
    setProcessData(dataProcessFormatted);
  } else {
    //MOSTRAR ALERTA ERRONEA CON EL MENSAJE QUE RETORNA LA API COMPONENTE TALOBILLO
    console.log(getMyProcessDataRequest);
    setProcessData([]);
  }
};*/
/***********************************************************************************************************************************************/

//////////////////////////////////////////////////// FUNCION QUE REGRESA LOS DATOS CORRESPONDIENTES DE LA TABLA /////////////////////////////////////////////////////
export const getListProcessByStatus = async (
  props,
  setProcessData,
  setTotalProcedures,
  sizePerPage,
  page,
  statusId,
  subdomain,
  setShowModal,
  setModalElements
) => {
  const urlToConsume = await getUrlbasedInStatus(statusId, sizePerPage, page);
  const procedures = await getListProcedureBasedStatus(
    props,
    urlToConsume.url,
    urlToConsume.status,
    setTotalProcedures,
    statusId,
    subdomain,
    setShowModal,
    setModalElements
  );

  setProcessData(procedures ? procedures : []);
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////FUNCION QUE SETEA EL NOMBRE DE LOS FILTROS////////////////////////////////////////////////////////////
export const setFilterNameButton = id => {
  switch (id) {
    case 1:
      return "Pendiente";
    case 2:
      return "En revisión";
    case 3:
      return "Cancelado";
    case 4:
      return "Pausado";
    case 5:
      return "Finalizado";
    default:
      break;
  }
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/*export const getCountByFilter = (status, objState) => {
  //GET THE CURRENT COUNT BY STATUS
  switch (status) {
    case 1:
      return objState.pending;
    case 3:
      return objState.cancelled;
    case 5:
      return objState.finished;
    case 4:
      return objState.paused;
    case 2:
      return objState.review;
    default:
      return objState.pending;
  }
};*/

/*export const countTotalProcedures = async () => {
  const { userId_02 } = Cookies.get();
  const request = new Request(
    `${
      process.env.REACT_APP_URL_API_LARAVEL
    }/procedures_count/${userId_02}?token=${process.env.REACT_APP_TOKEN}`,
    "get",


  );

  const countProceduresData = await request.executeRequest();
  if (countProceduresData.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    return countProceduresData.data.data.data.procedures.procedures;
  } else {
    //MOSTRAR ALERTA ERRONEA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    console.log(countProceduresData);
  }
};*/
/***********************************************************************************************************************************************/

////////////////////////////////////////////////////////FUNCION QUE REGRESA LA URL DEL STATUS DEL TRAMITE////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//FUNCTION RETURN THE CORRECT URL FOR REQUEST BY FILTER
export const getUrlbasedInStatus = (id, sizePerPage, page) => {
  const { userId_02 } = Cookies.get();
  switch (id) {
    case 1:
      return {
        url: `${process.env.REACT_APP_URL_API_LARAVEL}/procedures_pending/${userId_02}/${sizePerPage}?page=${page}&token=${process.env.REACT_APP_TOKEN}`,
        status: "pending"
      };
    case 2:
      return {
        url: `${process.env.REACT_APP_URL_API_LARAVEL}/procedures_review/${userId_02}/${sizePerPage}?page=${page}&token=${process.env.REACT_APP_TOKEN}`,
        status: "review"
      };
    case 3:
      return {
        url: `${process.env.REACT_APP_URL_API_LARAVEL}/procedures_cancelled/${userId_02}/${sizePerPage}?page=${page}&token=${process.env.REACT_APP_TOKEN}`,
        status: "cancelled"
      };
    case 4:
      return {
        url: `${process.env.REACT_APP_URL_API_LARAVEL}/procedures_paused/${userId_02}/${sizePerPage}?page=${page}&token=${process.env.REACT_APP_TOKEN}`,
        status: "paused"
      };
    case 5:
      return {
        url: `${process.env.REACT_APP_URL_API_LARAVEL}/procedures_finished/${userId_02}/${sizePerPage}?page=${page}&token=${process.env.REACT_APP_TOKEN}`,
        status: "finished"
      };

    default:
      return {
        url: `${process.env.REACT_APP_URL_API_LARAVEL}/procedures_pending/${userId_02}/${sizePerPage}?page=${page}&token=${process.env.REACT_APP_TOKEN}`,
        status: "pending"
      };
  }
};

////////////////////////////////////////////////////////FUNCION QUE PINTA EL COLOR DEPENDIENDO DEL STATUS////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const printColorStatus = el => {
  let expiration_date = el => {
    if (el.expiration_date) {
      let d = new Date(el.expiration_date);
      return (
        d.getDate() +
        "-" +
        (d.getMonth() < 9 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1) +
        "-" +
        d.getFullYear()
      );
    }
    return "";
  };

  switch (el.color) {
    case "green":
      return (
        <Badge
          variant="success"
          title={
            el.expiration_date ? `Días restantes : ${el.remaining_days}` : ""
          }
          style={{padding:"5px"}}
        >
          {el.expiration_date ? expiration_date(el) : "No aplica"}
        </Badge>
      );
    case "yellow":
      return (
        <Badge
          variant="warning"
          title={
            el.expiration_date ? `Días restantes : ${el.remaining_days}` : ""
          }
          style={{padding:"5px"}}
        >
          {expiration_date(el)}
        </Badge>
      );
    case "red":
      return (
        <Badge
          variant="danger"
          title={el.expiration_date ? `Trámite vencido` : ""}
          style={{padding:"5px"}}
        >
          {expiration_date(el)}
        </Badge>
      );
    default:
      break;
  }
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////FUNCION QUE EJECUTA EL FORMULARIO EN EL CUAL SE QUEDO EL USUARIO, MANDA LOS DATOS AL REDIRIGIR AL FORMULARIO///////////////////////////////////////////////

export const resumeProcess = async (procedure_id, props, subdomain) => {
  const { userId_02 } = Cookies.get();
  const history = props && props.props && props.props.history ? props.props.history : null;
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/procedure/continue/${procedure_id}/${userId_02}?token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );

  const resumeProcessData = await request.executeRequest();
  if (resumeProcessData.code === 200) {
    const actionTo = {
      action: "continue",
      procedure_id: procedure_id
    }
    localStorage.setItem( "actionTo", JSON.stringify(actionTo));
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    if ( history ) await getFormBasedInUrl(
      resumeProcessData.data.data.data.next_url,
      history,
      subdomain
    )
    else {
      const response = await getFormBasedInUrl(
        resumeProcessData.data.data.data.next_url,
        history,
        subdomain
      );
      return response;
    }
  }
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const getFormBasedInUrl = async (url, history, subdomain) => {
  const request = new Request(url, "get", null, {});
  const formDataBasedUrl = await request.executeRequest();
  if (formDataBasedUrl.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    if (
      typeof formDataBasedUrl.data.data.data.message === "string" &&
      formDataBasedUrl.data.data.data.message !== ""
    )
      NotificationManager.success(formDataBasedUrl.data.data.data.message);

    const meta = formDataBasedUrl.data.data.data.meta;
    const form = formDataBasedUrl.data.data.data.form;

    if ( history ) history.push({
      pathname: `/${subdomain}/render_form`,
      state: {
        params: {
          stage: meta.stage_id,
          sequence: meta.current_step,
          form: form,
          formTitle: meta.form_name,
          previous_url: formDataBasedUrl.data.data.data.previous_url,
          mode: meta.mode,
          applicant: meta.applicant_id
        }
      }
    })
    else return {
      meta: meta,
      form: form
    };
  }
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////FILTRO PARA MANDAR LLAMAR LA FUNCION CORRESPONDIENTE///////////////////////////////////////////////

const getListProcedureBasedStatus = async (
  props,
  url,
  status,
  setTotalProcedures,
  statusId,
  subdomain,
  setShowModal,
  setModalElements
) => {
  let data = {};
  if (status === "pending") {
    data = getDataPendingStatus(
      url,
      props,
      setTotalProcedures,
      statusId,
      subdomain
    );
  }
  if (status === "cancelled") {
    data = getDataCancelledStatus(
      url,
      props,
      setTotalProcedures,
      statusId,
      subdomain,
      setShowModal,
      setModalElements
    );
  }
  if (status === "finished") {
    data = getDataFinishStatus(
      url,
      props,
      setTotalProcedures,
      statusId,
      subdomain,
      setShowModal,
      setModalElements
    );
  }
  if (status === "paused") {
    data = getDataPausedStatus(
      url,
      props,
      setTotalProcedures,
      statusId,
      subdomain,
      setShowModal,
      setModalElements
    );
  }
  if (status === "review") {
    data = getDataReviewStatus(
      url,
      props,
      setTotalProcedures,
      statusId,
      subdomain,
      setShowModal,
      setModalElements
    );
  }

  return data;
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// CONSTRUYE LOS DATOS DE LA TABLA CON EL ESTADO DE PENDING /////////////////////////////////////////////////////

const getDataPendingStatus = async (
  url,
  props,
  setTotalProcedures,
  statusId,
  subdomain
) => {
  const data = await makeRequestUrlData(
    url,
    props,
    setTotalProcedures,
    statusId
  );
  let proceduresListPending = [];
  let newObject = {};
  const procedures = data.data.data.data.procedures
    ? data.data.data.data.procedures
    : [];
  procedures.forEach((el, index) => {
    //SI EL EL.ACTION.DELETE ESTA SETEADO EL USUARIO PODRA ELIMINAR EL REGISTRO DE LO CONTRARIO NO SE MOSTRARA LA OPCION
    const DeletedElementComponent = el.actions.delete ? (
      <DeleteProcedure el={el} props={props} />
    ) : (
      ""
    );
    el.action = checkIfDateIsInThePast(el.stages[0].expiration_date) ? 
      ( <Row center="xs" around="xs">
          <Col xs={12} className="procedures-actions">
            {DeletedElementComponent}
          </Col>
        </Row> 
      ) : (
        <Row center="xs" around="xs">
          <Col xs={12} className="procedures-actions">
            <div
              className="edit"
              onClick={() => resumeProcess(el.procedure_id, props, subdomain)}
            >
              <i
                className="ti-pencil-alt mr-1"
                title="Continuar edición del trámite"
              />
            </div>
            {DeletedElementComponent}
          </Col>
        </Row>
      );
    newObject = {
      ...el,
      color: printColorStatus(el.stages[0]),
      expired_at: ""
    };
    proceduresListPending.push(newObject);
  });
  return proceduresListPending;
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// CONSTRUYE LOS DATOS DE LA TABLA CON EL ESTADO DE CANCELLED /////////////////////////////////////////////////////

const getDataCancelledStatus = async (
  url,
  props,
  setTotalProcedures,
  statusId,
  subdomain,
  setShowModal,
  setModalElements
) => {
  const data = await makeRequestUrlData(
    url,
    props,
    setTotalProcedures,
    statusId
  );
  let proceduresListCancelled = [];
  let newObject = {};
  const procedures = data.data.data.data.procedures
    ? data.data.data.data.procedures
    : [];
  procedures.forEach((el, index) => {
    el.action = (
      <Row center="xs" around="xs">
        <Col xs={12}>
          <ViewDetailSelect el={el} setShowModal={setShowModal} setModalElements={setModalElements} />
        </Col>
      </Row>
    );
    newObject = {
      ...el,
      color: printColorStatus(el.stages[0] ? el.stages[0].color : ""),
      expired_at: ""
    };
    proceduresListCancelled.push(newObject);
  });

  return proceduresListCancelled;
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// CONSTRUYE LOS DATOS DE LA TABLA CON EL ESTADO DE REVIEW /////////////////////////////////////////////////////

const getDataReviewStatus = async (
  url,
  props,
  setTotalProcedures,
  statusId,
  subdomain,
  setShowModal,
  setModalElements
) => {
  const data = await makeRequestUrlData(
    url,
    props,
    setTotalProcedures,
    statusId
  );
  let proceduresListReview = [];
  let newObject = {};
  const procedures = data.data.data.data.procedures
    ? data.data.data.data.procedures
    : [];

  procedures.forEach((el, index) => {
    el.action = (
      <Row center="xs" around="xs">
        <Col xs={12}>
          <ViewDetailSelect el={el} setShowModal={setShowModal} setModalElements={setModalElements} />
        </Col>
      </Row>
    );

    newObject = {
      ...el,
      percentage_completation: getProgessTag(el.percentage_completation),
      expired_at: ""
    };
    proceduresListReview.push(newObject);
  });

  return proceduresListReview;
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// CONSTRUYE LOS DATOS DE LA TABLA CON EL ESTADO DE PAUSED /////////////////////////////////////////////////////

const getDataPausedStatus = async (
  url,
  props,
  setTotalProcedures,
  statusId,
  domain,
  setShowModal,
  setModalElements
) => {
  const data = await makeRequestUrlData(
    url,
    props,
    setTotalProcedures,
    statusId
  );
  let proceduresListPaused = [];
  let newObject = {};
  const procedures = data.data.data.data.procedures
    ? data.data.data.data.procedures
    : [];
  procedures.forEach((el, index) => {
    el.action = (
      <Row center="xs" around="xs">
        <Col xs={12}>
          <ViewDetailSelect el={el} setShowModal={setShowModal} setModalElements={setModalElements} />
        </Col>
      </Row>
    );
    newObject = {
      ...el,
      percentage_completation: getProgessTag(el.percentage_completation),
      expired_at: ""
    };
    proceduresListPaused.push(newObject);
  });

  return proceduresListPaused;
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// CONSTRUYE LOS DATOS DE LA TABLA CON EL ESTADO DE FINISHED /////////////////////////////////////////////////////

const getDataFinishStatus = async (
  url,
  props,
  setTotalProcedures,
  statusId,
  subdomain,
  setShowModal,
  setModalElements
) => {
  const data = await makeRequestUrlData(
    url,
    props,
    setTotalProcedures,
    statusId
  );
  let proceduresListFinished = [];
  let newObject = {};
  const procedures = data.data.data.data.procedures
    ? data.data.data.data.procedures
    : [];
  procedures.forEach((el, index) => {
    el.action = (
      <Row center="xs" around="xs">
        <Col xs={12}>
          <ViewDetailSelect el={el} setShowModal={setShowModal} setModalElements={setModalElements} />
        </Col>
      </Row>
    );
    newObject = {
      ...el,
      color: printColorStatus(""),
      expired_at: ""
    };
    proceduresListFinished.push(newObject);
  });
  return proceduresListFinished;
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////FUNCION QUE TRAE LOS DATOS DE LAS TABLAS/////////////////////////////////////////////////////////////////

const makeRequestUrlData = async (url, props, setTotalProcedures, statusId) => {
  const request = new Request(url, "get", null, {});
  const listProcedureData = await request.executeRequest();
  if (listProcedureData.code === 200) {
    setTotalProcedures(listProcedureData.data.data.data.total);
    return listProcedureData;
  } else {
    //MOSTRAR ALERTA ERRONEA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    return listProcedureData;
  }
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const getProgessTag = progress => {
  if (progress !== "") {
    return <span className="text-success font-weight-bold">{progress}%</span>;
  }

  return <span className="text-success font-weight-bold">0%</span>;
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const searchProcedure = async (
  match,
  setProcedures,
  setTotalProcedures,
  sizePerPage,
  page,
  currentStatusFilter,
  props,
  force = false,
  subdomain
) => {
  if (match.length > 0 && force) {
    const { userId_02 } = Cookies.get();
    const url = `${
      process.env.REACT_APP_URL_API_LARAVEL
    }/procedures/citizen_search/${userId_02}/${match}/${sizePerPage}/${page}/${currentStatusFilter}?token=${process.env.REACT_APP_TOKEN}`;
    let status = getUrlbasedInStatus(currentStatusFilter);

    getListProcedureBasedStatus(
      props,
      url,
      status.status,
      setTotalProcedures,
      currentStatusFilter
    )
      .then(procedures => {
        setProcedures(procedures);
      })
      .catch(error => {
        console.log(error);
        setProcedures([]);
        setTotalProcedures(0);
      });
  } else if (match === "") {
    await getListProcessByStatus(
      props,
      setProcedures,
      setTotalProcedures,
      sizePerPage,
      page,
      currentStatusFilter,
      subdomain
    );
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// FUNCTION TO CHECK IF AN EXPIRATION_DATE IS IN THE PAST 
const checkIfDateIsInThePast = (fecha) => {
  if ( fecha !== "" ) {
    let miliSec = Date.parse(fecha);
    let now = Date.parse(new Date());
    if ( miliSec > now ) return false
    else return true;
  }
  return false;
};