import React from "react";
import Cookies from "js-cookie";
import { destroyCookies } from "../../login/login_logic";
import { Badge } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { NewClassRequest } from "../../classes/NewClassRequest";
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////FUNCION QUE TRAE LOS DATOS DE LAS TABLAS/////////////////////////////////////////////////////////////////

export const logOut = async props => {
  const { token_02, uid_02, client_02 } = Cookies.get();

  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/auth/sign_out`,
    "delete",
    null,
    {
      access_token_name: token_02,
      uid_name: uid_02,
      client_name: client_02
    }
  );

  const responseLogOutData = await request.executeRequest();
  if (responseLogOutData.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE DESPUES DE DESLOGUEARSE
    if (
      !responseLogOutData.response.error &&
      typeof responseLogOutData.response.message === "string" &&
      responseLogOutData.response.message !== ""
    ) {

      setTimeout(() => {
        NotificationManager.success(responseLogOutData.response.message);
      }, 1000);

      await destroyCookies();
    }
  }
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const renderNames = (data) => {
  if (data === 'null'){
    return '';
  }
  return data;
};

export function capital_letter(str) {
  str = str.trim();
  str = str.split(" ");

  for (var i = 0, x = str.length; i < x; i++) {
    str[i] = str[i][0].toUpperCase() + str[i].substr(1);
  }

  return str.join(" ");
}

export const setImgAvatar = (countAlerts) => {
  const { isAuth_02, names_02, paternal_surname_02 } = Cookies.get();

  if (isAuth_02) {
      return (
        <div className="pull-right">
          <Badge
            className="pull-right mL-10"
            pill
            variant="danger"
            style={ countAlerts === 0 ? 
              { 
                display: "none",
                float: "left" 
              } : 
              { 
                display: "inline-block",
                float: "left" 
              }
            }
          >
            {countAlerts !== 0 ? countAlerts : 0}
          </Badge>{" "}
          <span style={{display: "inline-block", float: "right"}}>
            {renderNames(names_02)} {renderNames(paternal_surname_02)}
          </span>
        </div>
      );
  } else {
    return "";
  }
};
