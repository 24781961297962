import React, { useState, useContext, useEffect } from "react";
import { Card, Form, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import GroupButtonsForm from "../helpers_components/GroupSubmitFormButtons";
import {
  register,
  initForm,
  termsRequest,
  ModalTermsAndConditions
} from "./register_logic";
import GoogleRecaptcha from "../helpers_components/GoogleRecaptcha";
import Plain from "../layout/containers/Plain";
import LoaderContext from "../layout/shared/loader_context";
import DomainContext from "../domains/domainPathContext";
import {renderErrorsList, renderClassesToErrors} from "../confirm_registration/confirm_registration_logic";
import Cookies from "js-cookie";


const Register = props => {
  const subdomain = useContext(DomainContext);
  const [form, setForm] = useState(initForm);
  const Loader = useContext(LoaderContext);
  const [recaptcha, setRecaptcha] = useState("");
  const [handleTermsModal, setHandleTermsModal] = useState(false);
  const [termsText, setTermsText] = useState(false);
  const [termsTitle, setTermsTitle] = useState("");
  const [canShowModal, setCanShowModal] = useState(true);
  const [errors, setErrors] = useState({});
  const layout = JSON.parse(Cookies.get("layout_02"));

  useEffect(() => {
    (async function() {
      await termsRequest(setTermsTitle, setTermsText, setCanShowModal);
    })();
  }, []);

  const handleChangeForm = event => {
    setForm({
      ...form,
      [event.target.name]: event.target.value
    });
    Object.keys(errors).forEach((error)=> {
      if (error === event.target.name) {
          delete errors[event.target.name];
      }
    });
  };

  const onSubmitFormHandler = async event => {
    event.preventDefault();
    Loader.show(true);
    await register(form, recaptcha, props, subdomain, setErrors);
    Loader.show(false);
  };

  const ModalToTermsAndConditions = ModalTermsAndConditions(
    handleTermsModal,
    setHandleTermsModal,
    termsText,
    termsTitle
  );

  return (
    <Plain {...props} noHeader={true} noFooter={true}>
      <Container
        fluid={true}
        className="min-height-100vh bg-size-cover"
        style={{
          backgroundImage: layout && layout['app_background_undefined_session'] ? 
            `url(${layout['app_background_undefined_session']})` :
            "url(" + process.env.PUBLIC_URL + "/img/login_wallpaper.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"
        }}
      >
        <Container>
          <Row style={{ minHeight: "100vh" }}>
            <Col
              sm={12}
              md={{ span: 10, offset: 1 }}
              lg={{ span: 8, offset: 2 }}
              xl={{ span: 6, offset: 3 }}
              className="mY-30"
            >
              <Card className="mY-40@sm+">
                <Card.Header>
                  <Container>
                    <Row>
                      <Col
                        xs={12}
                        md={{ span: 10, offset: 1 }}
                        className="pT-20 pB-10 pT-30@sm+ pB-20@sm+"
                      >
                        <h3 className="mb-0">Crear Cuenta</h3>
                      </Col>
                    </Row>
                  </Container>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs={12} md={{ span: 10, offset: 1 }} className="pT-30">
                      <p className="mB-30">
                        Para registrarte, por favor completa el siguiente
                        formulario.
                      </p>

                      <Form onSubmit={onSubmitFormHandler} className="mB-20">
                        <Form.Group>
                          <Form.Label className="font-weight-bold">
                            * Regimen Fiscal
                          </Form.Label>
                          <Form.Control
                            name="regime"
                            onChange={handleChangeForm}
                            as="select"
                            required
                          >
                            <option value="">Regimen Fiscal...</option>
                            <option value="1">Persona Fisica</option>
                            <option value="2">Persona Moral</option>
                          </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formBasicNombre">
                          <Form.Label className="font-weight-bold">
                            * Nombre de Usuario
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Nombre de Usuario"
                            required
                            autoFocus
                            name="username"
                            onChange={handleChangeForm}
                            autoComplete="off"
                            className={renderClassesToErrors(errors, "username")}
                          />
                          {renderErrorsList(errors, "username")}
                        </Form.Group>

                        <Form.Group controlId="formBasicCorreoElectronico">
                          <Form.Label className="font-weight-bold">
                            * Correo
                          </Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Correo Electronico"
                            required
                            name="email"
                            onChange={handleChangeForm}
                            autoComplete="off"
                            className={renderClassesToErrors(errors, "email")}
                          />
                          {renderErrorsList(errors, "email")}
                        </Form.Group>

                        <Form.Group controlId="formSelectAplicante">
                          <Form.Label className="font-weight-bold">
                            * Tipo de Usuario
                          </Form.Label>
                          <Form.Control
                            onChange={handleChangeForm}
                            as="select"
                            name="user_type"
                            required
                          >
                            <option value="">Tipo de Aplicante...</option>
                            <option value="owner">Interesado</option>
                            <option value="manager">Gestor</option>
                          </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formBasicCorreoContraseña">
                          <Form.Label className="font-weight-bold">
                            * Contraseña
                          </Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Contraseña"
                            required
                            name="password"
                            onChange={handleChangeForm}
                            className={renderClassesToErrors(errors, "password")}
                          />
                          {renderErrorsList(errors, "password")}
                        </Form.Group>

                        <Form.Group controlId="formBasicCorreoConfirmarContraseña">
                          <Form.Label className="font-weight-bold">
                            * Confirmar contraseña
                          </Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Confirmar Contraseña"
                            required
                            name="password_confirmation"
                            onChange={handleChangeForm}
                            className={renderClassesToErrors(errors, "password_confirmation")}
                          />
                          {renderErrorsList(errors, "password_confirmation")}
                        </Form.Group>

                        <div>
                          <Link to={`/${subdomain}/`}>Ya tengo cuenta</Link>
                        </div>
                        <div>
                          <p>
                            * Al registrarme, aseguro haber leído y aceptado los
                            <span
                              onClick={
                                canShowModal
                                  ? () => {
                                      setHandleTermsModal(true);
                                    }
                                  : null
                              }
                              className="cursor-pointer text-primary px-1"
                            >
                              términos y condiciones
                            </span>
                            del uso del sistema.
                          </p>
                        </div>

                        <div className="overflow-auto w-100">
                          <GoogleRecaptcha
                            validate={value => setRecaptcha(value)}
                          />
                        </div>
                        {!recaptcha ? renderErrorsList(errors,"recaptcha") : null}
                        <GroupButtonsForm
                          primaryText="Registrarme"
                          secondaryText="Cancelar"
                          urlToCancel={`/${subdomain}`}
                        />
                      </Form>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
      <>
        {ModalToTermsAndConditions}
      </>
    </Plain>
  );
};

export default Register;
