import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row
} from "react-bootstrap";
import Dropzone from "react-dropzone";
import { uploadAvatar } from "./avatar_config_logic";
import OneColumn from "../layout/containers/OneColumn";
import Cookies from "js-cookie";
import DomainContext from "../domains/domainPathContext";
import LoaderContext from "../layout/shared/loader_context";
import { renderErrorsList } from "../confirm_registration/confirm_registration_logic";


const AvatarConfig = props => {
  const [fileImg, setFileImg] = useState("");
  const [previewFileImgUrl, setPreviewImgUrl] = useState("");
  const [previewFileImage, setPreviewFileImage] = useState(false);
  const [imageData, setImageData] = useState({ route: "", status: 0 });
  const subdomain = useContext(DomainContext);
  const loader = useContext(LoaderContext);
  const [errors, setErrors] = useState({});
  const layout = JSON.parse(Cookies.get("layout_02"));
  const { authentication_token_02 } = Cookies.get();
  const [temporaryURL, setTemporaryURL] = useState("");

  useEffect(() => {
    (async function() {
      const { avatar_02 } = Cookies.get();
      if (avatar_02 && avatar_02 !== "null") {

        setImageData({ route: avatar_02, status: 2, type:"request" });
      } else {
        setImageData({
          route: `${process.env.PUBLIC_URL}/img/user_avatar.png`,
          status: 2,
          type: "local"
        });
      }
    })();
  }, []);

  const onDrop = acceptedFiles => {
    if ( acceptedFiles.length > 0 ) {
        let file = acceptedFiles[0];
        const reader = new FileReader();
    
        setImageData({ route: URL.createObjectURL(file), status: 2, type:'local' });
    
        reader.onload = event => {
          setFileImg(
            event.target.result.replace(/^data:image\/[a-z]+;base64,/, "")
          );
        };
        reader.readAsDataURL(file);
    }
    else setErrors({avatar:["Formato no válido, sólo se aceptan imágenes en formato .PNG"]});
  };

  const onSubmitFormHandler = async e => {
    e.preventDefault();
    loader.show(true);
    await uploadAvatar(fileImg, subdomain);
    loader.show(false);
  };

  let pageConfig = {
    active: "",
    title: ""
  };

  const previewFileImgHandler = () => {
    if (temporaryURL !== "") {
      setPreviewImgUrl(temporaryURL);
    }
    else {
      setPreviewImgUrl(imageData.route);
    }
    setPreviewFileImage(true);
  };

  const downloadAvatarWithRequest = (url) => {
    const { userId_02 } = Cookies.get();
    let a = document.createElement('a');
    a.href = url;
    a.download = 'avatar_user_' + userId_02 + '.png';
    a.click();  
  };

  return (
    <OneColumn
      {...props}
      pageConfig={pageConfig}
      hideTopMenu={true}
      fluid={true}
      noTitle={true}
    >
      <Container
        fluid={true}
        className="bg-size-cover"
        style={{
          minHeight: "76vh",
          backgroundImage: layout && layout['app_background_session'] ? 
              `url(${layout['app_background_session']})` :
              "url(" + process.env.PUBLIC_URL + "/img/login_wallpaper.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"
        }}
      >
        <Container>
          <Row>
            <Col
              sm={12}
              md={{ span: 8, offset: 2 }}
              lg={{ span: 6, offset: 3 }}
              className="mY-30"
            >
              <Modal
                size="lg"
                show={previewFileImage}
                onHide={() => setPreviewFileImage(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton className="bg-complement-3">
                  <Modal.Title id="example-modal-sizes-title-lg">
                    Previsualización de imagen
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="w-100p ta-c">
                    <img 
                      width="50%"
                      src={previewFileImgUrl}/>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setPreviewFileImage(false)}
                  >
                    Cerrar
                  </Button>
                </Modal.Footer>
              </Modal>

              <Card className="mY-40@sm+">
                <Card.Header>
                  <Container>
                    <Row>
                      <Col className="pT-20 pB-10 pT-30@sm+ pB-20@sm+">
                        <h3 className="mB-20 text-center">
                          Subir imagen de avatar
                        </h3>
                      </Col>
                    </Row>
                  </Container>
                </Card.Header>
                <Card.Body>
                  <Form onSubmit={onSubmitFormHandler}>
                    <Row>
                      <Col className={"mT-20"}>
                        <Form.Group controlId="formBasicFile">
                          <p className="text-center h6"><b>NOTA:</b> Sólo son válidas imágenes en formato PNG</p>
                          <Dropzone accept={['.png']} onDrop={onDrop}>
                            {({ getRootProps, getInputProps }) => (
                              <div
                                className="file-nilo-wrapper"
                                {...getRootProps()}
                              >
                                Selecciona un archivo
                                <input
                                  style={{ display: "inline!important" }}
                                  className="form-control"
                                  {...getInputProps()}
                                />
                              </div>
                            )}
                          </Dropzone>
                        </Form.Group>

                        <div className="file-upload-wrapper">
                          <div
                            className={
                              "file-upload-actions " +
                              (imageData.status === 2 ? "d-block" : "d-none")
                            }
                          >
                            <i
                              className="fas fa-search-plus cursor-pointer"
                              onClick={() => previewFileImgHandler()}
                              title="preview image"
                            />
                            <i
                              className="fas fa-cloud-download-alt cursor-pointer"
                              onClick={() => downloadAvatarWithRequest(previewFileImgUrl)}
                              title="dowload file"
                            />
                          </div>
                          <img
                            className={
                              "loader " +
                              (imageData.status === 1 ? "d-block" : "d-none")
                            }
                            src={process.env.PUBLIC_URL + "/img/loader.gif"}
                            alt="Cargando ..."
                          />
                          <img
                            src={imageData['type'] === 'local' ? imageData['route'] : ''}
                            alt="File"
                            className={
                              imageData.status === 2 ? "d-block" : "d-none"
                            }
                            ref={(input) => {
                              if (
                                input && 
                                imageData['type'] === 'request' && 
                                imageData['route'] && 
                                temporaryURL === "") {

                                input.alt = "Creando previsualización del archivo...";
                                fetch(imageData['route'], { headers:{ Authorization: authentication_token_02 } })
                                .then( response => response.blob().then(
                                  blob => {
                                    let temporaryURL = URL.createObjectURL(blob);
                                    input.src = temporaryURL;
                                    setTemporaryURL(temporaryURL);
                                  }
                                ))
                              }
                            }}
                          />
                        </div>
                        {renderErrorsList(errors, "avatar")}
                      </Col>
                    </Row>

                    <Row className="btn-group-sign-up mt-4 pY-20">
                      <Col xs={12} sm={12}>
                        <Button
                          style={{ width: "45%" }}
                          variant="secondary"
                          onClick={() =>
                            props.history.push(`/${subdomain}/home`)
                          }
                        >
                          Cancelar
                        </Button>
                        <Button
                          style={{ width: "45%" }}
                          variant="primary"
                          type={fileImg === "" ? "button" : "submit"}
                          className="d-block float-right"
                        >
                          Subir
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </OneColumn>
  );
};
export default AvatarConfig;
